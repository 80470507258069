<template>
  <div class="crumbs">
    <el-breadcrumb>
      <el-breadcrumb-item  v-for="(n,index) in list" :key="index">{{n.meta.name}}</el-breadcrumb-item>
      <slot name="after"></slot>
    </el-breadcrumb>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      list: this.$route.matched
    };
  },
  watch: {
      $route:{
          immediate:true,//初始化立即执行
          deep: true,//对象深度监测
          handler: function (to){
              this.list = to.matched;
          }
      },
  },
  mounted(){

  },
  methods:{

  }
};
</script>

<style lang="scss" scoped>
.crumbs{
  padding:15px 25px;
  line-height: 40px;
  background:#fff;
  border-bottom:#ddd solid 1px;
}
</style>
